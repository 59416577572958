import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { Container, Row, Col, Card } from "react-bootstrap";

const Clients = ({ loading }) => {
  const hRef = useRef(null); // Ref for the heading
  const hiRef = useRef(null); // Ref for "Hi" text
  const exclamationRef = useRef(null); // Ref for "!" text
  const progressRef = useRef(null); // Ref for the progress bar

  const clientElements = useRef([]); // Ref for storing client elements

  const clients = [
    {
      name: "saicel.ca",
      logo: "https://saicel.ca/wp-content/uploads/2024/10/logo.png",
      link: "https://saicel.ca/",
      bgColor: "#ffffff",
    },
  ];

  useEffect(() => {
    if (!loading) {
      // Trigger animations only after loading is complete
      const animateText = () => {
        gsap.fromTo(
          hiRef.current,
          { opacity: 0, y: -50, scale: 0.8, rotation: 5 }, // Added rotation for realism
          {
            opacity: 1,
            y: 0,
            scale: 1,
            rotation: 0, // Reset rotation
            duration: 1.5,
            ease: "bounce.out",
          }
        );

        gsap.fromTo(
          hRef.current,
          { opacity: 0, y: 50, scale: 0.9 }, // Added scale for depth effect
          {
            opacity: 1,
            y: 0,
            scale: 1, // Reset scale
            duration: 1.5,
            ease: "power3.out",
          }
        );
      };

      const animateExclamation = () => {
        gsap.fromTo(
          exclamationRef.current,
          { scale: 1, rotation: 0 }, // Start without rotation
          {
            scale: 2,
            rotation: 10, // Add a little rotation
            duration: 1.2,
            ease: "power1.inOut",
            onComplete: () => {
              gsap.to(exclamationRef.current, {
                scale: 1,
                rotation: 0, // Reset rotation
                duration: 0.6,
                ease: "power1.out",
              });
            },
          }
        );
      };

      // Progress bar scroll animation
      const updateProgressBar = () => {
        const scrollTop = window.scrollY;
        const docHeight =
          document.documentElement.scrollHeight - window.innerHeight;
        const progress = (scrollTop / docHeight) * 100;

        gsap.to(progressRef.current, {
          width: `${progress}%`,
          duration: 0.2,
        });
      };

      // Add scroll event listener for progress bar
      window.addEventListener("scroll", updateProgressBar);

      // Initial animations
      animateText();
      animateExclamation();

      // Animate client cards when they appear on screen
      const animateCards = () => {
        clientElements.current.forEach((card) => {
          gsap.fromTo(
            card,
            { opacity: 0, y: 50, scale: 0.9, rotation: -15 }, // Start below, invisible, and rotated
            {
              opacity: 1,
              y: 0,
              scale: 1, // Reset scale
              rotation: 0, // Reset rotation
              duration: 1.2,
              ease: "power3.out",
              scrollTrigger: {
                trigger: card,
                start: "top bottom", // Trigger when card is in view
                toggleActions: "play none none none",
              },
            }
          );
        });
      };

      animateCards();

      // Cleanup event listeners on component unmount
      return () => {
        window.removeEventListener("scroll", updateProgressBar);
      };
    }
  }, [loading]);

  return (
    <section
      id="hero"
      className="jumbotron gradient-background"
      style={{
        overflow: "visible",
        position: "relative",
      }}
    >
      {/* Progress Bar */}
      <div
        className="progress-bar"
        ref={progressRef}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          height: "5px",
          backgroundColor: "#FF5733",
          width: "0%",
          zIndex: 9999, // Ensure it is on top
        }}
      ></div>

      <Container>
        {/* Animated Heading */}
        <h2
          ref={hRef}
          className="section-title text-center mb-4"
          style={{ fontSize: "3rem", fontWeight: "bold", opacity: 0 }}
        >
          My ongoing projects
        </h2>
        <Row className="justify-content-center">
          {clients.map((client, index) => (
            <Col md={6} lg={4} className="text-center" key={index}>
              <a
                href={client.link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none"
              >
                <Card
                  className="shadow-sm h-100 p-3"
                  ref={(el) => (clientElements.current[index] = el)}
                  style={{
                    backgroundColor: client.bgColor,
                    borderRadius: "12px",
                    transition: "transform 0.3s ease, box-shadow 0.3s ease", // Add smooth transition
                  }}
                >
                  <Card.Body>
                    <img
                      src={client.logo}
                      alt={client.name}
                      className="mb-3"
                      style={{ width: "160px", height: "60%" }}
                    />
                    <Card.Title
                      style={{ fontWeight: "bold", fontSize: "1.2rem" }}
                    >
                      {client.name}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </a>
            </Col>
          ))}
        </Row>
      </Container>

      {/* Floating icons */}
      <div
        className="floating-icons"
        style={{
          position: "absolute",
          bottom: "10%",
          left: "0",
          right: "0",
          display: "flex",
          justifyContent: "space-around",
          zIndex: 1,
        }}
      >
        <i className="fas fa-briefcase" style={{ fontSize: "30px" }}></i>{" "}
        {/* Briefcase for work */}
        <i className="fas fa-tasks" style={{ fontSize: "30px" }}></i>{" "}
        {/* Tasks for project management */}
        <i
          className="fas fa-project-diagram"
          style={{ fontSize: "30px" }}
        ></i>{" "}
        {/* Project Diagram for work flow */}
        <i className="fas fa-laptop-code" style={{ fontSize: "30px" }}></i>{" "}
        {/* Laptop for coding */}
        <i className="fas fa-cogs" style={{ fontSize: "30px" }}></i>{" "}
        {/* Cogs for settings/work tools */}
      </div>
    </section>
  );
};

export default Clients;
