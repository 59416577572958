import { useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faReact, faNode, faLaravel, faWordpress } from "@fortawesome/free-brands-svg-icons"; // Add WordPress icon
import { faDesktop, faServer, faCode, faDatabase, faTools, faShieldAlt } from "@fortawesome/free-solid-svg-icons"; // Use faShieldAlt for Cybersecurity
import { Container, Row, Col, Card } from "react-bootstrap"; // Import Bootstrap components
import gsap from "gsap"; // Ensure GSAP is imported

const SkillsSection = () => {
  const skillElements = useRef([]);

  const skills = [
    {
      title: "React",
      icon: faReact, // React icon
      color: "#61DAFB", // React color
    },
    {
      title: "Node.js & Express",
      icon: faNode, // Node.js icon
      color: "#68A063", // Node.js color
    },
    {
      title: "Laravel",
      icon: faLaravel, // Laravel icon
      color: "#FF2D20", // Laravel color
    },
    {
      title: "WordPress",
      icon: faWordpress, // WordPress icon
      color: "#21759B", // WordPress color
    },
  ];

  useEffect(() => {
    // GSAP animation for skill elements
    gsap.from(skillElements.current, {
      duration: 1.2,
      opacity: 0,
      y: 30,
      scale: 0.95,
      stagger: 0.25,
      ease: "power3.out",
    });
  }, []);

  return (
    <section id="skills">
      <Container>
        <h2 className="section-title text-center">Known Tech Stacks</h2>
        <Row>
          {skills.map((skill, index) => (
            <Col lg={4} md={6} className="mb-4" key={index} ref={(el) => (skillElements.current[index] = el)}>
              <Card className="text-center shadow-sm h-100" style={{ borderRadius: "15px", transition: "transform 0.3s, box-shadow 0.3s" }}>
                <Card.Body>
                  <FontAwesomeIcon 
                    icon={skill.icon} 
                    size="5x" // Increased icon size
                    style={{ color: skill.color }} // Inline style for icon color
                    className="mb-3" 
                  />
                  <Card.Title>{skill.title}</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      <style jsx>{`
        .card:hover {
          transform: scale(1.05); // Scale up on hover
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); // Add shadow on hover
        }
      `}</style>
    </section>
  );
};

const RectangleSection = () => {
  const sectionElements = useRef([]);

  const rectangleData = [
    {
      title: "Frontend Development",
      icon: faDesktop, // Desktop icon for Frontend Development
      description: "Building modern and responsive websites using various frontend technologies.",
    },
    {
      title: "Backend Development",
      icon: faCode, // Code icon for Backend Development
      description: "Creating and managing server-side logic, building APIs, and database integrations.",
    },
    {
      title: "Database Management",
      icon: faDatabase, // Database icon for Database Management
      description: "Managing databases and ensuring data integrity and performance optimization.",
    },
    {
      title: "Cybersecurity",
      icon: faShieldAlt, // Shield icon for Cybersecurity
      description: "Implementing security measures to protect systems and networks, including penetration testing.",
    },
    {
      title: "cPanel & VPS Management",
      icon: faServer, // Server icon for cPanel & VPS Management
      description: "Managing cPanel for web hosting and configuring unmanaged VPS for custom server setups.",
    },
    {
      title: "Deployment",
      icon: faTools, // Tools icon for Deployment
      description: "Deploying applications on servers, managing hosting environments, and ensuring smooth CI/CD processes.",
    },
    {
      title: "WordPress Design , Plugin And Theme Development",
      icon: faWordpress, // WordPress icon for Design and Customization
      description: "Managing WordPress themes, designing custom layouts, and Developing custom WordPress plugins optimizing user experience.",
    },
  ];

  useEffect(() => {
    // GSAP animation for section elements
    gsap.from(sectionElements.current, {
      duration: 1.2,
      opacity: 0,
      x: -30,
      scale: 0.95,
      stagger: 0.25,
      ease: "power3.out",
    });
  }, []);

  return (
    <section id="rectangle-section" style={{ backgroundColor: "#f4f4f9", padding: "60px 0" }}>
      <Container>
        <h2 className="section-title text-center">What I Do</h2>
        <Row>
          {rectangleData.map((item, index) => (
            <Col lg={4} md={6} className="mb-4" key={index} ref={(el) => (sectionElements.current[index] = el)}>
              <Card className="text-center shadow-sm h-100" style={{ borderRadius: "10px", transition: "transform 0.3s, box-shadow 0.3s" }}>
                <Card.Body>
                  <FontAwesomeIcon 
                    icon={item.icon} 
                    size="4x" // Icon size adjusted
                    style={{ color: "#333" }} // Icon color for all items
                    className="mb-3"
                  />
                  <Card.Title>{item.title}</Card.Title>
                  <Card.Text>{item.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      
      <style jsx>{`
        .card:hover {
          transform: scale(1.05); // Scale up on hover
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); // Add shadow on hover
        }
      `}</style>
    </section>
  );
};

const App = () => {
  return (
    <div>
      <SkillsSection />
      <RectangleSection />
    </div>
  );
};

export default App;
